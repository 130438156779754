import request from "../utils/request";

const api = {
  login(userName, password) {
    return request.post("login", {
      userName,
      password
    });
  },

  // -----运营部营收分析-----
  // 获取租户信息
  getTenant() {
    return request.get("/tenant/getSelfTenant");
  },

  /**
   * 运营部营收分析-总营收图表情况
   * @param {*} tenantId 租户id(必传--全部传所有,逗号隔开)
   * @param {*} year 年--必传
   * @param {*} month 月--必传
   * @returns
   */
  getTotalRevenueData(tenantId, year, month, parkingId = null) {
    let params = {
      tenantId,
      year,
      month
    };
    if (parkingId) params.parkingId = parkingId;
    return request.get("/operation-income-analysis/income-info", params);
  },

  /**
   * 运营部营收分析-车场营收分析
   * @param {*} tenantId 租户id(必传--全部传所有,逗号隔开)
   * @param {*} year 年--必传
   * @param {*} month 月--必传
   * @returns
   */
  getParkingIncome(tenantId, year, month, parkingId = null) {
    let params = {
      tenantId,
      year,
      month
    };
    if (parkingId) params.parkingId = parkingId;
    return request.get(
      "/operation-income-analysis/parking-income-analysis",
      params
    );
  },

  /**
   * 运营部营收分析-营收分析
   * @param {*} tenantId 租户id(必传--全部传所有,逗号隔开)
   * @param {*} year 年--必传
   * @param {*} month 月--必传
   * @returns
   */
  getTotalOperation(tenantId, year, month, parkingId = null) {
    let params = {
      tenantId,
      year,
      month
    };
    if (parkingId) params.parkingId = parkingId;
    return request.get("/operation-income-analysis/income-analysis", params);
  },

  /**
   * 运营部营收分析-月营收对比分析
   * @param {*} tenantId 租户id(必传--全部传所有,逗号隔开)
   * @param {*} currentYear 当前年,必传
   * @param {*} currentMonth 当前月必传
   * @param {*} lastYear 过去所选年必传
   * @param {*} lastMonth 过去所选月必传
   * @returns
   */
  getMonthlyOperationData(
    tenantId,
    currentYear,
    currentMonth,
    lastYear,
    lastMonth,
    parkingId = null
  ) {
    let params = {
      tenantId,
      currentYear,
      currentMonth,
      lastYear,
      lastMonth
    };
    if (parkingId) params.parkingId = parkingId;
    return request.get(
      "/operation-income-analysis/income-compare-analysis",
      params
    );
  },

  /**
   * 运营部营收分析-营收年同比
   * @param {*} tenantId 租户id(必传--全部传所有,逗号隔开)
   * @param {*} year 年--必传
   * @param {*} month 月--必传
   * @returns
   */
  getAnnualRate(tenantId, year, type, parkingId) {
    let params = {
      tenantId,
      year,
      type
    };
    if (parkingId) params.parkingId = parkingId;
    return request.get(
      "/operation-income-analysis/income-year-on-year-analysis",
      params
    );
  },

  getRecord({
    tenantId,
    startDate,
    endDate,
    streetCode,
    parkingName,
    sortField,
    sortOrder = "ascend",
    parkingId,
    pageNum = 1
  }) {
    return request.get("/parking-record-analysis/parking-record", {
      tenantId,
      parkingId,
      startDate,
      endDate,
      streetCode,
      parkingName,
      sortOrder,
      sortField,
      pageNum
    });
  },

  // 获取下拉框内容
  getTenantAndStreetAndParkingInfo() {
    return request.get("/street/getTenantAndStreetAndParkingInfo");
  },

  // 获取已读消息
  getUserMsg(pageNum, isRead) {
    return request.get("/user-msg/targerUserMsg", { pageNum, isRead });
  },

  updateUserMsg(ids) {
    return request.post("/user-msg/update", {
      ids
    });
  }
};

export default api;
