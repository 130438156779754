export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/Index.vue")
  },
  {
    path: "/message",
    name: "Message",
    component: () => import("../views/Message.vue")
  },
  {
    path: "/tenantOperation",
    name: "TenantOperation",
    component: () => import("../views/TenantOperation.vue"),
    children: [
      {
        path: "revenueDetail",
        name: "RevenueDetail",
        component: () => import("../views/TenantOperation/RevenueDetail.vue")
      }
    ]
  },
  {
    path: "/parkingOperation",
    name: "ParkingOperation",
    component: () => import("../views/ParkingOperation.vue")
  },
  {
    path: "/parkingData",
    name: "ParkingData",
    component: () => import("../views/ParkingData.vue")
  },
  {
    path: "/tabSelect",
    name: "TabSelect",
    component: () => import("../views/tabSelect.vue")
  },
  {
    path: "/",
    redirect: "/index"
  }
];
