<template>
  <transition name="fade">
    <div v-show="isShow" class="popup-mask">
      <transition name="popup">
        <div v-show="isShow" class="popup-wrapper" :style="{ width }">
          <slot></slot>
        </div>
      </transition>
      <div class="mask" @click="closePopup"></div>
    </div>
  </transition>
</template>

<script>
export default {
  model: {
    prop: "isShow",
    event: "changeShow"
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "60%"
    }
  },
  methods: {
    closePopup() {
      this.$emit("changeShow", false);
    }
  }
};
</script>

<style lang="less" scoped>
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.popup-enter-to,
.popup-leave {
  transform: translateX(0);
}
.popup-enter,
.popup-leave-to {
  transform: translateX(-100%);
}
.popup-enter-active,
.popup-leave-active {
  transition: all 0.3s ease;
}
.popup-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}
.popup-wrapper {
  width: 60%;
  height: 100%;
  background-color: #fff;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 101;
}

.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(7, 17, 27, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>
