<template>
    <div class="title-bar">
        <div class="icon-box">
            <span @click="onClickSelfIcon" class="icon" :class="[ leftIcon ]" v-if="leftIcon"></span>
            <span @click="onClickBack" class="icon icon-back" v-else></span>
        </div>
        <span class="title">{{ title }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: "",
            },
            leftIcon: {
                type: String,
                default: ""
            },
        },
        methods: {
            onClickBack() {
                this.$router.go(-1);
            },
            onClickSelfIcon() {
                this.$emit('onClickSelfIcon')
            }
        }
    }
</script>

<style lang="less" scoped>
    .title-bar {
        display: flex;
        align-items: center;
        height: 44px;
        min-height: 44px;
        width: 100%;
        background-color: #003399;
    }

    .title {
        font-size: 16px;
        font-family: "黑体";
        color: #fff;
    }

    .icon-box {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 14px;
        margin-right: 8px;
    }

    .icon {
        font-size: 16px;
        color: #fff;
    }
</style>