import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import db from "../utils/localStorage";

Vue.use(VueRouter);

const router = new VueRouter({
  routes
});

let whiteList = [];
let clearList = ["Login"];

router.beforeEach((to, from, next) => {
  let routerName = to.name;
  if (whiteList.includes(routerName)) {
    // 白名单页面不做处理
    next();
  } else if (clearList.includes(routerName)) {
    // 该数组路由全需清空localStorage
    db.clear();
    next();
  } else {
    // 检查token
    let accessToken = db.get("_A", "");
    if (!accessToken) {
      // 没有token就跳到登录页面
      next({ name: "Login" });
      // next();
    } else {
      // token存在，继续跳转
      next();
    }
  }
});

export default router;
