import PopupLayer from "./PopupLayer.vue";
import TitleBar from "./TitleBar.vue";

/**
 * 记录需全局注册的组件
 * 在main.js使用use全局注册
 */
const globalComponents = {
  install(Vue) {
    Vue.component("PopupLayer", PopupLayer);
    Vue.component("titleBar", TitleBar);
  }
};

export default globalComponents;
