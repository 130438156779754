const db = {
  get(props) {
    return JSON.parse(localStorage.getItem(props)) || "";
  },

  set(props, val) {
    return localStorage.setItem(props, JSON.stringify(val));
  },

  remove(props) {
    localStorage.removeItem(props);
  },

  clear() {
    localStorage.clear();
  }
};

export default db;
