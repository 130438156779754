import db from "../../utils/localStorage";

export default {
  namespaced: true,
  state: {
    accessTokenExipreTime: db.get("_A_T", ""),
    refreshTokenExipreTime: db.get("_R_T", ""),
    accessToken: db.get("_A", ""),
    refreshToken: db.get("_T", ""),
    userName: db.get("USER_NAME", ""),
    roleName: db.get("ROLE_NAME", ""),
    avatar: db.get("ACATAR", "")
  },
  mutations: {
    setAccessTokenExipreTime(state, val) {
      db.set("_A_T", val);
      state.accessTokenExipreTime = val;
    },
    setRefreshTokenExipreTime(state, val) {
      db.set("_R_T", val);
      state.refreshTokenExipreTime = val;
    },
    setAccessToken(state, val) {
      db.set("_A", val);
      state.accessToken = val;
    },
    setRefreshToken(state, val) {
      db.set("_T", val);
      state.refreshToken = val;
    },
    setUserName(state, val) {
      db.set("USER_NAME", val);
      state.userName = val;
    },
    setRoleName(state, val) {
      db.set("ROLE_NAME", val);
      state.roleName = val;
    },
    setAvatar(state, val) {
      db.set("ACATAR", val);
      state.avatar = val;
    }
  }
};
