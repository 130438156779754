import echartsUtils from "./echartsUtils";

const utils = {
  ...echartsUtils,

  getAvatarUrl(fileName) {
    return require("../assets/avatar/" + fileName);
  }
};

export default utils;
