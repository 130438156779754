import * as echarts from "echarts";

const echartsUtils = {
  initEchart(el) {
    let myChart = echarts.init(el);
    window.addEventListener("resize", () => {
      myChart.resize();
    });

    return myChart;
  },

  /**
   * 刷新Echart图表数据
   * @param {*} echartEl Echart实例
   * @param {*} data 图表数据
   *    {
   *     unitPsIncome, // 单车位收入
   *     onlineIncome, // 线上收入
   *     prepayAmount, // 预缴收入
   *     offlineQrcodeIncome, // 线下二维码
   *     cashAmount // 现金收入
   *    }
   */
  refreshDoughnutChart(echartEl, data) {
    let option = {
      series: [
        {
          name: "营收分析",
          type: "pie",
          radius: ["60%", "80%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center"
          },
          emphasis: {
            label: {
              show: false,
              fontSize: "40",
              fontWeight: "bold"
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: 0,
              name: "单车位收入",
              key: "unitPsIncome",
              itemStyle: {
                color: "#fb6b67"
              }
            },
            {
              value: 0,
              name: "线上收入",
              key: "onlineIncome",
              itemStyle: {
                color: "#ffb6f2"
              }
            },
            {
              value: 0,
              name: "预缴收入",
              key: "prepayAmount",
              itemStyle: {
                color: "#5acc8e"
              }
            },
            {
              value: 0,
              name: "线下二维码",
              key: "offlineQrcodeIncome",
              itemStyle: {
                color: "#fcc274"
              }
            },
            {
              value: 0,
              name: "现金收入",
              key: "cashAmount",
              itemStyle: {
                color: "#7ec8f8"
              }
            }
          ]
        }
      ]
    };

    if (data) {
      option.series[0]["data"].forEach(
        item => (item["value"] = data[item["key"]])
      );
    }

    echartEl.setOption(option);
  },

  refreshRateChart(echartEL, key1, data1, key2, data2) {
    let option = {
      tooltip: {
        trigger: "axis",
        borderColor: "#5e5e5e",
        backgroundColor: "rgba(7,17,27,0.4)",
        textStyle: {
          color: "#fff",
          fontSize: 12
        },
        formatter: params => {
          let v1 = params[0]["value"];
          let v2 = params[1]["value"];
          let str = `${params[0]["name"]}月</br>`;

          str += `${params[0]["seriesName"]}：${v1}元</br>`;
          str += `${params[1]["seriesName"]}：${v2}元</br>`;
          if (!params[0].value || !params[1].value) {
            str += `增长率：--%`;
          } else {
            str += `增长率：${(((v2 - v1) / v1) * 100).toFixed(2)}%`;
          }
          return str;
        }
      },
      legend: {
        data: [key1, key2],
        bottom: "0%"
      },
      grid: {
        left: "6%",
        right: "6%",
        top: "8%",
        bottom: "10%",
        containLabel: true
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: value => {
            switch (true) {
              case value >= 1000 && value < 10000:
                return `${Math.floor(value / 1000)}k`;
              case value >= 10000 && value < 1000000:
                return `${Math.floor(value / 10000)}w`;
              case value > 1000000:
                return `${Math.floor(value / 1000000)}m`;

              default:
                return value;
            }
          }
        }
      },
      series: [
        {
          name: key1,
          type: "line",
          stack: key1,
          symbol: "circle",
          symbolSize: "6",
          data: data1,
          itemStyle: {
            color: "#7cb5ec"
          }
        },
        {
          name: key2,
          type: "line",
          stack: key2,
          symbol: "diamond",
          symbolSize: "6",
          data: data2,
          itemStyle: {
            color: "#434348"
          }
        }
      ]
    };

    echartEL.setOption(option);
  }
};

export default echartsUtils;
