import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//引入字体库样式
import "./assets/css/style.css";

//引入vant
import vant from "vant";
import "vant/lib/index.css";
Vue.use(vant);

//引入rem
import "amfe-flexible";

Vue.config.productionTip = false;

import globalComponents from "./components/globalComponents";
Vue.use(globalComponents);

import utils from "./utils/utils";
import request from "./utils/request";
import api from "./api/api";
import db from "./utils/localStorage";
Vue.prototype.$utils = utils;
Vue.prototype.$db = db;
Vue.prototype.$get = request.get;
Vue.prototype.$post = request.post;
Vue.prototype.$put = request.put;
Vue.prototype.$delete = request.delete;
Vue.prototype.$api = api;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
